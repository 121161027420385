/* * * * * * GENERATED FILE - DO NOT EDIT * * * * * *
 * * * * * * GENERATED FILE - DO NOT EDIT * * * * * *
 * * * * * * GENERATED FILE - DO NOT EDIT * * * * * */
 
export default {
	"de": {
		credits: {
			"default": "Erstellt mit Flourish",
		},
	},
	"en": {
		credits: {
			"default": "A Flourish data visualization",
			"chart": "A Flourish chart",
			"map": { text: "A Flourish map", url: "https://flourish.studio/visualisations/maps/" },
			"survey": { text: "A Flourish survey visualization", url: "https://flourish.studio/visualisations/survey-data/" },
			"network": { text: "A Flourish network chart", url: "https://flourish.studio/visualisations/network-charts/" },
			"scatter": { text: "A Flourish scatter chart", url: "https://flourish.studio/visualisations/scatter-charts/" },
			"sankey": { text: "A Flourish sankey chart", url: "https://flourish.studio/visualisations/sankey-charts/" },
			"quiz": "A Flourish quiz",
			"bar_race": { text: "A Flourish bar chart race", url: "https://flourish.studio/visualisations/bar-chart-race/" },
			"bar-chart-race": { text: "A Flourish bar chart race", url: "https://flourish.studio/visualisations/bar-chart-race/" },
			"cards": "Interactive content by Flourish",
			"chord": "A Flourish chord diagram",
			"election": "A Flourish election chart",
			"globe": { text: "A Flourish connections globe", url: "https://flourish.studio/visualisations/maps/" },
			"hierarchy": { text: "A Flourish hierarchy chart", url: "https://flourish.studio/visualisations/treemaps/" },
			"line-chart-race": "A Flourish line chart race",
			"parliament": "A Flourish election chart",
			"photo-slider": "Interactive content by Flourish",
			"slope": { text: "A Flourish slope chart", url: "https://flourish.studio/visualisations/slope-charts/" },
			"sports": "A Flourish sports visualization",
			"explore": "A Flourish data visualization",
			"word-cloud": "A Flourish data visualization"
		}
	},
	"es": {
		credits: {
			"default": "Creado con Flourish",
			"bar_race": { text: "Créé avec Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
			"bar-chart-race": { text: "Créé avec Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
		},
	},
	"fr": {
		credits: {
			"default": "Créé avec Flourish",
			"bar_race": { text: "Créé avec Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
			"bar-chart-race": { text: "Créé avec Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
		},
	},
	"it": {
		credits: {
			"default": "Creato con Flourish",
			"bar_race": { text: "Creato con Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
			"bar-chart-race": { text: "Creato con Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
		}
	},
	"mi": {
		credits: {
			"default": "Hangaia ki te Flourish",
			"bar_race": { text: "Hangaia ki te Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
			"bar-chart-race": { text: "Hangaia ki te Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
		},
	},
	"nl": {
		credits: {
			"default": "Gemaakt met Flourish",
			"bar_race": { text: "Gemaakt met Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
			"bar-chart-race": { text: "Gemaakt met Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/" },
		},
	},
	"pt": {
		"default": "Feito com Flourish",
		"bar_race": { text: "Feito com Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/"},
		"bar-chart-race": { text: "Feito com Flourish", url: "https://flourish.studio/visualisations/bar-chart-race/"}
	}
};
