import { flourishify } from "@flourish/transform-data";

import { createFlourishCredit, getLocalizedCreditTextAndUrl } from "./embed/credit";
import initEmbedding from "./embed/embedding";

var VERSION = "4.5.0";

var DEFAULTS = {
	api_url: "https://flourish-api.com/api/v1/live",
	public_bucket_prefix: "https://public.flourish.studio/"
};

// Properties that cannot (yet) be changed on update():
var IMMUTABLE_PROPERTIES = [
	"api_key", "template", "version", "container", "base_visualisation_id"
];

function stringify(o) {
	if (!o && o !== 0) return "";
	else if (typeof o === "object") {
		for (var k in o) o[k] = stringify(o[k]);
		return o;
	}
	else return "" + o;
}

function shallowCopy(o) {
	var r = {};
	for (var k in o) r[k] = o[k];
	return r;
}

function isObject(x) {
	return !Array.isArray(x) && typeof x === "object" && x != null;
}

// Expects an object at the top level.
// Does not deep-copy arrays, which is okay here
// since the data structures we expect to receive
// have arrays only of strings.
function deepCopy(obj) {
	if (obj == null) return obj;
	var copy = {};
	for (var k in obj) {
		if (Array.isArray(obj[k])) {
			copy[k] = obj[k].slice();
		}
		else if (isObject(obj[k])) {
			copy[k] = deepCopy(obj[k]);
		}
		else {
			copy[k] = obj[k];
		}
	}
	return copy;
}

var embedding = null;
function Fleet(opts) {
	this._validateOpts(opts);

	this.template_loaded = false;
	this.metadata_loaded = false;
	this.company_state = null;
	this.template_settings = null;
	this._queued_methods = [];

	for (var prop in DEFAULTS) {
		if (!opts.hasOwnProperty(prop)) opts[prop] = DEFAULTS[prop];
	}

	if (opts.base_visualisation_id) {
		var that = this;
		this._loadBaseVisualisation(opts, function(error, base) {
			if (error) {
				console.error(error.message);
				return;
			}
			opts = mergeObjects(base, opts);
			that._loadFleet(opts);
		});
	}
	else {
		this._loadFleet(opts);
	}
}

Fleet.prototype._loadBaseVisualisation = function Fleet__loadBaseVisualisation(opts, callback) {
	var xhr = new XMLHttpRequest();
	xhr.addEventListener("load", function() {
		if (this.status != 200) {
			var error = new Error("Fetching the base visualisation failed");
			return callback(error);
		}
		var parsed_json = JSON.parse(this.responseText);
		return callback(null, parsed_json);
	});

	xhr.open("GET", opts.public_bucket_prefix + "visualisation/" + opts.base_visualisation_id + "/visualisation.json");
	xhr.send();
};

Fleet.prototype._loadFleet = function Fleet__loadFleet(opts) {
	this.original_properties = {};
	for (var i = 0; i < IMMUTABLE_PROPERTIES.length; i++) {
		var k = IMMUTABLE_PROPERTIES[i];
		this.original_properties[k] = opts[k];
	}

	if (!embedding) embedding = initEmbedding();
	var embed_url = opts.api_url + "/template?api_key=" + opts.api_key + "&template=" + encodeURIComponent(opts.template) + "&version=" + opts.version;

	var container = (typeof opts.container === "string") ? document.querySelector(opts.container) : opts.container;

	this.iframe = embedding.createEmbedIframe(embed_url, container, opts.width, opts.height, false);

	var that = this;
	this.iframe.addEventListener("load", function() {
		that.template_loaded = true;
		if (that.metadata_loaded) that._init(opts.state, that._data, opts.callback);
	});

	embedding.startEventListeners(function(message, frame) {
		if (message.method == "resize") {
			if (typeof message.height == "number") message.height += "px";
			if (message.height) frame.style.height = message.height;
		}
	});

	var xhr = new XMLHttpRequest();
	xhr.addEventListener("load", function () {
		if (this.status === 500) {
			console.error(JSON.parse(this.responseText));
			return;
		}
		if (this.status != 200) {
			console.error("Fetching the template and data bindings from the server failed");
			return;
		}
		var parsed_json = JSON.parse(this.responseText);

		that._prepareDataBindings(parsed_json.data_bindings);
		that.template_settings = parsed_json.settings || {};
		that.company_state = that._getCompanyState(parsed_json.company_custom);
		that.metadata_loaded = true;
		that._prepareData(opts);
		if (that.template_loaded) that._init(opts.state, that._data, opts.callback);

		if (!parsed_json.hide_credit) {
			var template_name = opts.template.replace(/^@?flourish\//, "");
			var local_credits = getLocalizedCreditTextAndUrl(opts.lang, template_name);
			var credit = createFlourishCredit(local_credits.credit_url,
				null, null, local_credits.credit_text);
			container.appendChild(credit);
		}
	});

	xhr.open("GET", opts.api_url + "/metadata?api_key=" + opts.api_key + "&template=" + encodeURIComponent(opts.template) + "&version=" + opts.version);
	xhr.send();
};

// Calculate the base state which the state passed to the API is
// merged over. This will return an empty object unless the API key
// owner is in a company with custom settings.
Fleet.prototype._getCompanyState = function Fleet__getCompanyState(company_custom) {
	if (!company_custom) return {};
	return company_custom.settings || {};
};

function isNonArrayObject(o) {
	return (o instanceof Object) && !Array.isArray(o) && o !== null;
}

function mergeObjects(o1, o2) {
	// Deep clone the first object so we won't modify it on merging:
	var k, v, result = JSON.parse(JSON.stringify(o1));
	for (k in o2) {
		v = o2[k];
		// If both corresponding values are objects, recursively
		// merge them, otherwise o2's value is used:
		if (isNonArrayObject(result[k]) && isNonArrayObject(v)) {
			result[k] = mergeObjects(result[k], v);
		}
		else result[k] = v;
	}
	return result;
}

Fleet.prototype._mergeState = function Fleet__mergeState(state) {
	return mergeObjects(this.company_state, state);
};

Fleet.prototype._prepareDataBindings = function Fleet__prepareDataBindings(data_bindings_array) {
	var data_bindings = {};

	for (var i = 0; i < data_bindings_array.length; i++) {
		var d = data_bindings_array[i];
		if (typeof d === "string") continue;

		if (!(d.dataset in data_bindings)) {
			data_bindings[d.dataset] = [];
		}
		data_bindings[d.dataset].push(d);
	}

	this._data_bindings = data_bindings;
	this._parsed_bindings = {};

	for (var dataset in data_bindings) {
		this._parseDataset(dataset);
	}
};

Fleet.prototype._parseDataset = function Fleet__parseDataset(dataset) {
	if (!this._parsed_bindings[dataset]) {
		var kd = this._parsed_bindings[dataset] = {
			dataset: dataset,
			mandatory_keys: [],
			optional_keys: [],
			columns_keys: [],
			default_values: {},
			has_mandatory_key: false
		};

		var data_bindings = this._data_bindings;
		for (var key in data_bindings[dataset]) {
			var d = data_bindings[dataset][key];
			switch (d.type) {
				case "column":
					if (!d.optional) {
						kd.mandatory_keys.push(d.key);
						kd.has_mandatory_key = true;
					}
					else {
						kd.optional_keys.push(d.key);
					}
					break;

				case "columns":
					kd.default_values[d.key] = [];
					kd.columns_keys.push(d.key);
					break;
			}
		}
	}
};

Fleet.prototype._getColumnNames = function Fleet__getColumnNames(kd, column_names, optional_keys_used, number_of_columns) {
	var result = {};

	var dataset = kd.dataset;
	var column_name;
	for (var i = 0; i < kd.mandatory_keys.length; i++) {
		var mandatory_key = kd.mandatory_keys[i];
		column_name = (column_names && column_names[dataset] && column_names[dataset][mandatory_key]) || mandatory_key;

		result[mandatory_key] = column_name;
	}

	for (var i = 0; i < kd.optional_keys.length; i++) {
		var optional_key = kd.optional_keys[i];
		if (!optional_keys_used[optional_key]) continue;
		column_name = (column_names && column_names[dataset] && column_names[dataset][optional_key]) || optional_key;

		result[optional_key] = column_name;
	}

	for (var i = 0; i < kd.columns_keys.length; i++) {
		var columns_key = kd.columns_keys[i];
		if (column_names && column_names[dataset] && column_names[dataset][columns_key]) {
			column_name = column_names[dataset][columns_key];
			if (typeof column_name === "string") column_name = [column_name];
			if (!Array.isArray(column_name) || column_name.length != number_of_columns[columns_key]) {
				throw new Error("Flourish: number of column names (" + column_name.length
					+ ") does not match the number of columns (" + number_of_columns[columns_key]
					+ ") for dataset “" + dataset + "” and key “" + columns_key + "”");
			}
		}
		else {
			column_name = [];
			for (var j = 0; j < number_of_columns[columns_key]; j++) {
				column_name.push(columns_key + " " + (j+1));
			}
		}

		result[columns_key] = column_name;
	}

	return result;
};

function arrayToObjectKeys(arr) {
	return arr.reduce(function(obj, key) {
		obj[key] = true;
		return obj;
	}, {});
}

function getOrCreateDataset(data, dataset) {
	if (!data[dataset]) {
		data[dataset] = [];
		data[dataset].column_names = {};
	}
	return data[dataset];
}

function splitBindings(dataset, bindings, kd) {
	var result = { column_bindings: {}, columns_bindings: {} };
	for (var k in bindings) {
		var v = bindings[k];
		// FIXME: make a simple object lookup in kd instead of repeatedly iterating over these arrays
		if (kd.columns_keys.indexOf(k) >= 0) {
			result.columns_bindings[k] = v;
		}
		else if (kd.mandatory_keys.indexOf(k) >= 0 || kd.optional_keys.indexOf(k) >= 0) {
			result.column_bindings[k] = v;
		}
		else {
			throw new Error("Flourish: unknown binding “" + k + "” found for dataset “" + dataset + "”");
		}
	}
	return result;
}

function addMissingColumnNames(dataset, parsed_bindings, data_bindings) {
	var column_names = dataset.column_names;
	var mandatory_keys = arrayToObjectKeys(parsed_bindings.mandatory_keys);
	for (var i = 0; i < data_bindings.length; i++) {
		var binding = data_bindings[i];
		var key = binding.key;
		if (column_names[key] !== undefined) continue;
		if (binding.type === "columns") column_names[key] = [];
		else if (mandatory_keys[key]) column_names[key] = binding.name;
	}
}

// This function will take a row from a dataset in the shape that
// Flourish expects and do the following:
//   - add default values for any columns or optional column types
//   - do a number of checks for consistency of the data, and throw
//     an exception on finding any inconsistency
//   - record which optional keys have been used in the
//     optional_keys_used object.
//   - record the expected number of values for each columns type
function fixRow(d, kd, optional_keys_used, number_of_columns) {
	// Assign default values
	for (var k in kd.default_values) {
		if (!(k in d)) d[k] = kd.default_values[k];
	}

	// Check that mandatory keys are present in each row
	for (var j = 0; j < kd.mandatory_keys.length; j++) {
		var mandatory_key = kd.mandatory_keys[j];
		if (!(mandatory_key in d)) {
			throw new Error("required key “" + mandatory_key + "” is missing");
		}
	}

	// Check that optional keys are used or not used consistently,
	// and record which are used in  the optional_keys_used object.
	for (var j = 0; j < kd.optional_keys.length; j++) {
		var optional_key = kd.optional_keys[j];
		if (optional_key in optional_keys_used) {
			if (optional_keys_used[optional_key] != (optional_key in d)) {
				throw new Error("the optional key “" + optional_key + "” is used in some rows but not in others");
			}
		}
		else {
			optional_keys_used[optional_key] = (optional_key in d);
		}
	}

	// Check that columns keys are used consistently, and record
	// how many columns each uses, in the number_of_columns object.
	//
	// TODO: Should we support having an inconsistent number of entries in a columns key?
	// We could assume the longest array determines the length.
	for (var j = 0; j < kd.columns_keys.length; j++) {
		var columns_key = kd.columns_keys[j];

		// If an atomic value is passed where an array is expected, treat it
		// as a single-element array.
		if (typeof d[columns_key] !== "object") {
			d[columns_key] = [ d[columns_key] ];
		}
		if (columns_key in number_of_columns) {
			if (number_of_columns[columns_key] != (d[columns_key].length)) {
				throw new Error("the columns key “" + columns_key + "” has an inconsistent number of entries");
			}
		}
		else {
			number_of_columns[columns_key] = d[columns_key].length;
		}
	}
}

Fleet.prototype._prepareData = function Fleet__prepareData(opts) {
	if ("column_names" in opts) this.column_names = deepCopy(opts.column_names);
	if (opts.bindings) {
		this._prepareDataFromExternalFormat(opts.data, opts.bindings);
	}
	else {
		this._prepareDataFlourishShape(opts.data, this.column_names);
	}
};

Fleet.prototype._prepareDataFromExternalFormat = function Fleet__prepareDataFromExternalFormat(data, bindings) {
	this._data = {};

	for (var dataset in bindings) {
		var kd = this._parsed_bindings[dataset]; // kd is short for “key data”
		var bindings_object = splitBindings(dataset, bindings[dataset], kd);
		var reshaped_data = flourishify(data[dataset] || [], bindings_object.column_bindings, bindings_object.columns_bindings);

		var number_of_columns = {};
		var optional_keys_used = {};
		for (var i = 0; i < reshaped_data.length; i++) {
			try {
				fixRow(reshaped_data[i], kd, optional_keys_used, number_of_columns, dataset);
			}
			catch (e) {
				throw new Error("Flourish: in dataset “" + dataset + "”, " + e.message);
			}
		}

		this._data[dataset] = reshaped_data;
	}

	// Fill in missing datasets and column names
	for (var dataset in this._data_bindings) {
		var d = getOrCreateDataset(this._data, dataset);
		var parsed_bindings = this._parsed_bindings[dataset];
		var data_bindings = this._data_bindings[dataset];
		addMissingColumnNames(d, parsed_bindings, data_bindings);
	}
};

Fleet.prototype._prepareDataFlourishShape = function Fleet__prepareDataFlourishShape(data, column_names) {
	var data_bindings = this._data_bindings;

	for (var dataset in data) {
		if (!(dataset in data_bindings)) {
			throw new Error("Flourish: the dataset “" + dataset + "” is not supported by this template");
		}
	}

	this._data = {};
	for (var dataset in data_bindings) {
		var kd = this._parsed_bindings[dataset]; // kd is short for “key data”

		if (kd.has_mandatory_key && !(dataset in data)) {
			throw new Error("Flourish: the dataset “" + dataset + "” must be specified");
		}

		var number_of_columns = {};
		var optional_keys_used = {};
		this._data[dataset] = [];
		for (var i = 0; i < data[dataset].length; i++) {
			var d = shallowCopy(data[dataset][i]);
			this._data[dataset].push(d);
			try {
				fixRow(d, kd, optional_keys_used, number_of_columns);
			}
			catch (e) {
				throw new Error("Flourish: in dataset “" + dataset + "”, " + e.message);
			}
		}

		this._data[dataset].column_names = this._getColumnNames(kd, column_names, optional_keys_used, number_of_columns);
	}
};

Fleet.prototype._init = function Fleet__init(state, data, callback) {
	var that = this;
	that._send("setFixedHeight", null, function() {
		that._draw(state, data, function() {
			if (callback) callback(that);

			for (var i = 0; i < that._queued_methods.length; i++) {
				var m = that._queued_methods[i];
				m[0].apply(that, m.slice(1));
			}
			that._queued_methods = null;
		});
	});
};

Fleet.prototype._queue = function Fleet__queue() {
	// Convert the pseudo-array arguments to a real array args.
	var args = [];
	for (var i = 0; i < arguments.length; i++) {
		args.push(arguments[i]);
	}

	// If initialisation is complete and the queued methods
	// have already been run, then run this method immediately
	// rather than queueing it.
	if (!this._queued_methods) {
		args[0].apply(this, args.slice(1));
		return;
	}

	// Otherwise add it to the queue
	this._queued_methods.push(args);
};

function wrapInQueue(f) {
	return function() {
		var args = [ f ];
		for (var i = 0; i < arguments.length; i++) {
			args.push(arguments[i]);
		}
		this._queue.apply(this, args);
	};
}

Fleet.prototype._send = function Fleet__send(method, argument, callback) {
	var channel = new MessageChannel();
	channel.port1.onmessage = callback;

	this.iframe.contentWindow.postMessage({
		sender: "Flourish",
		method: method,
		argument: argument
	}, "*", [channel.port2]);
};

Fleet.prototype._draw = function Fleet_draw(state, data, callback) {
	return this._send("sync", {
		draw: true,
		state: this._mergeState(state),
		data: stringify(data)
	}, callback);
};

Fleet.prototype._update = function Fleet__update(state, data, callback) {
	var argument = {
		update: true,
		state: this._mergeState(state)
	};
	if (data) {
		argument.data = stringify(data);
	}
	return this._send("sync", argument, callback);
};

Fleet.prototype._validateOpts = function Fleet__validateOpts(opts, update) {
	if (update) {
		for (var i = 0; i < IMMUTABLE_PROPERTIES.length; i++) {
			var k = IMMUTABLE_PROPERTIES[i];
			if (k in opts && opts[k] != this.original_properties[k]) {
				throw new Error("Flourish: changing the '" + k + "' is not yet supported");
			}
		}
	}

	if (opts.bindings && opts.column_names) {
		throw new Error(
			"Flourish: you must supply exactly one of opts.bindings and opts.column_names - " +
			"these correspond to different ways that your data might be shaped"
		);
	}
};

Fleet.prototype.getState = wrapInQueue(function Fleet_getState(callback) {
	return this._send("getState", null, function(obj) {
		if (!("data" in obj) || !("result" in obj.data)) {
			return callback(new Error("Template state not found"));
		}
		return callback(null, obj.data.result);
	});
});

Fleet.prototype.update = wrapInQueue(function Fleet_update(opts, callback) {
	this._validateOpts(opts, true);
	// FIXME (?): one might conceivably want to change the bindings or
	// column names on update, in which case _prepareData should be
	// re-run on the data which was last passed in. We're not sure
	// that we want to support this, however - it'd mean keeping an
	// extra copy of the passed in data in memory.
	if ("data" in opts) {
		this._prepareData(opts);
		return this._update(opts.state, this._data, callback);
	}
	return this._update(opts.state, undefined, callback);
});

export default {
	VERSION: VERSION,
	Live: Fleet
};
