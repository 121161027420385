/* * * * * * GENERATED FILE - DO NOT EDIT * * * * * *
 * * * * * * GENERATED FILE - DO NOT EDIT * * * * * *
 * * * * * * GENERATED FILE - DO NOT EDIT * * * * * */
 
/* This file is used by the story player, and must be IE-compatible */

import {
	initCustomerAnalytics,
	addAnalyticsListener,
	sendCustomerAnalyticsMessage
} from "./customer_analytics";
import parseQueryParams from "./parse_query_params";

var is_fixed_height;
var is_amp;

function isFixedHeight() {
	if (is_fixed_height == undefined) {
		var params = parseQueryParams();
		// "referrer" in params implies this is an Embedly embed
		// Check whether embedding site is known to support dynamic resizing
		if ("referrer" in params) is_fixed_height = /^https:\/\/medium.com\//.test(params.referrer);
		else is_fixed_height = !("auto" in params);
	}
	return is_fixed_height;
}

function getHeightForBreakpoint(width) {
	var breakpoint_width = width || window.innerWidth;
	if (breakpoint_width > 999) return 650;
	if (breakpoint_width > 599) return 575;
	return 400;
}

function notifyParentWindow(height, opts) {
	if (window.top === window.self) return;
	var embedded_window = window;
	if (embedded_window.location.pathname == "srcdoc") embedded_window = embedded_window.parent;
	if (is_amp) {
		// Message is not stringified for AMP
		height = parseInt(height, 10);
		embedded_window.parent.postMessage({
			sentinel: "amp",
			type: "embed-size",
			height: height,
		}, "*");
		return;
	}
	var message = {
		sender: "Flourish",
		context: "iframe.resize",
		method: "resize", // backwards compatibility
		height: height,
		src: embedded_window.location.toString(),
	};
	if (opts) {
		for (var name in opts) message[name] = opts[name];
	}
	embedded_window.parent.postMessage(JSON.stringify(message), "*");
}

function isSafari() {
	// Some example user agents:
	// Safari iOS: Mozilla/5.0 (iPhone; CPU iPhone OS 12_1_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1
	// Chrome OS X: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/71.0.3578.98 Safari/537.36
	// Embedded WkWebview on iOS: Mozilla/5.0 (iPhone; CPU iPhone OS 12_1_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/16D5039a
	return (navigator.userAgent.indexOf("Safari") !== -1 || navigator.userAgent.indexOf("iPhone") !== -1) && navigator.userAgent.indexOf("Chrome") == -1;
}

function startEventListeners(callback) {
	window.addEventListener("message", function(event) {
		// event.source is null when the message is sent by an extension
		// https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#Using_window.postMessage_in_extensions
		if (event.source == null) return;
		if (event.origin !== document.location.origin && !event.origin.match(/\/\/localhost:\d+$|\/\/flourish-api\.com$|\.flourish\.(?:local(:\d+)?|net|rocks|studio)$|\.uri\.sh$/)) return;
		var message;
		try {
			message = JSON.parse(event.data);
		}
		catch (e) {
			console.warn("Unexpected non-JSON message: " + JSON.stringify(event.data));
			return;
		}
		if (message.sender !== "Flourish") return;
		var frames = document.querySelectorAll("iframe");
		for (var i=0; i < frames.length; i++) {
			if (frames[i].contentWindow == event.source || frames[i].contentWindow == event.source.parent) {
				callback(message, frames[i]);
				return;
			}
		}
		console.warn("could not find frame", message);
	});

	if (isSafari()) {
		window.addEventListener("resize", onSafariWindowResize);
		onSafariWindowResize();
	}
}

function onSafariWindowResize() {
	// Ensure all iframes without explicit width attribute are sized to fit their container
	var containers = document.querySelectorAll(".flourish-embed");
	for (var i=0; i < containers.length; i++) {
		var container = containers[i];
		if (container.getAttribute("data-width")) continue;
		var iframe = container.querySelector("iframe");
		// When embeds are dynamically loaded, we might have a container without a
		// loaded iframe yet
		if (!iframe) continue;
		var computed_style = window.getComputedStyle(container);
		var width = container.offsetWidth - parseFloat(computed_style.paddingLeft) - parseFloat(computed_style.paddingRight);
		iframe.style.width = width + "px";
	}
}

function createEmbedIframe(embed_url, container, width, height, play_on_load) {
	var iframe = document.createElement("iframe");
	iframe.setAttribute("scrolling", "no");
	iframe.setAttribute("frameborder", "0");
	iframe.setAttribute("title", "Interactive or visual content");
	iframe.setAttribute("sandbox", "allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation");
	container.appendChild(iframe);

	// If the iframe doesn't have an offset parent, either the element or a parent
	// is set to display: none. This can cause problems with visualisation loading, so
	// we need to poll for the iframe being displayed before loading the visualisation.
	// FIXME: In Chrome, fixed position elements also return null for `offsetParent`.
	// The chances of an embed which is both position: fixed and display: none are
	// pretty small, so fuhgeddaboudit . If it's an issue in the future, we'll have to
	// recurse through the parent elements to make sure the iframe is displaying.
	if (iframe.offsetParent || getComputedStyle(iframe).position === "fixed") {
		setIframeContent(embed_url, container, iframe, width, height, play_on_load);
	}
	else {
		var poll_item = {
			embed_url: embed_url,
			container: container,
			iframe: iframe,
			width: width,
			height: height,
			play_on_load: play_on_load
		};
		// If this is the first embed on the page which is isn't displayed, set up a
		// list of hidden iframes to poll
		if (!window._flourish_poll_items) {
			window._flourish_poll_items = [poll_item];
		}
		else {
			// Otherwise, add this to the list of iframes which are being polled
			window._flourish_poll_items.push(poll_item);
		}

		if (window._flourish_poll_items.length > 1) {
			// If there were already items in the array then we have already started
			// polling in a different embed script, so we can return. This iframe will
			// have its contents set by the other embed script.
			return iframe;
		}

		// Poll to see whether any of the iframes have started displaying
		var interval = setInterval(function() {
			window._flourish_poll_items = window._flourish_poll_items.filter(function(item) {
				if (!item.iframe.offsetParent) {
					// It's still not displaying, so return true to leave it in the array
					return true;
				}

				// It's displaying, so set the content, and return false to remove it from
				// the array
				setIframeContent(item.embed_url, item.container, item.iframe, item.width, item.height, item.play_on_load);
				return false;
			});

			if (!window._flourish_poll_items.length) {
				// All of the iframes are displaying, so we can stop polling. If another
				// embed is added later, a new interval will be created by that embed script.
				clearInterval(interval);
			}
		}, 500);
	}
	return iframe;
}

function setIframeContent(embed_url, container, iframe, width, height, play_on_load) {
	var width_in_px;
	if (width && typeof width === "number") {
		width_in_px = width;
		width = "" + width + "px";
	}
	// The regular expression below detects widths that have been explicitly
	// expressed in px units. (It turns out CSS is more complicated than you may
	// have realised.)
	else if (width && width.match(/^[ \t\r\n\f]*([+-]?\d+|\d*\.\d+(?:[eE][+-]?\d+)?)(?:\\?[Pp]|\\0{0,4}[57]0(?:\r\n|[ \t\r\n\f])?)(?:\\?[Xx]|\\0{0,4}[57]8(?:\r\n|[ \t\r\n\f])?)[ \t\r\n\f]*$/)) {
		width_in_px = parseFloat(width);
	}

	if (height && typeof height === "number") height = "" + height + "px";
	// Odd design decision in Safari means need to set fixed width rather than %
	// as will try and size iframe to content otherwise. Must also set scrolling=no
	if (width) iframe.style.width = width;
	else if (isSafari()) iframe.style.width = container.offsetWidth + "px";
	else iframe.style.width = "100%";

	var fixed_height = !!height;
	if (!fixed_height) {
		if (embed_url.match(/\?/)) embed_url += "&auto=1";
		else embed_url += "?auto=1";
		// For initial height, use our standard breakpoints, based on the explicit
		// pixel width if we know it, or the iframe's measured width if not.
		height = getHeightForBreakpoint(width_in_px || iframe.offsetWidth) + "px";
	}

	if (height) {
		if (height.charAt(height.length - 1) === "%") {
			height = (parseFloat(height) / 100) * container.parentNode.offsetHeight + "px";
		}
		iframe.style.height = height;
	}

	iframe.setAttribute("src", embed_url + (play_on_load ? "#play-on-load" : ""));

	return iframe;
}

function initEmbedding() {
	is_amp = window.location.hash == "#amp=1";
	return {
		createEmbedIframe: createEmbedIframe,
		isFixedHeight: isFixedHeight,
		getHeightForBreakpoint: getHeightForBreakpoint,
		startEventListeners: startEventListeners,
		notifyParentWindow: notifyParentWindow,
		isSafari: isSafari,
		initCustomerAnalytics: initCustomerAnalytics,
		addAnalyticsListener: addAnalyticsListener,
		sendCustomerAnalyticsMessage: sendCustomerAnalyticsMessage
	};
}

export default initEmbedding;
